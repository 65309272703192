import React, { useState, useEffect } from "react";
import { Link, Navigate } from "react-router-dom";
import Summary from "./Summary";
import { useSelector, useDispatch } from "react-redux";
import Payment from "./Payment";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Alert, CircularProgress } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { clearCart } from "../../Services/Action/Action";
import { showSuccessSnackbar } from "../../Services/Action/snackbarActions";
import { useSnackbar } from "notistack";

const initialState = {
  name: "",
  email: "",
  phone: "",
  state: "",
  city: "",
  street: "",

  address: "",
  postal_code: "",
  payment_method: "",
  total_amount: 0,
  items: [],
};

const Checkout = ({ user }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [checkoutData, setCheckoutData] = useState(initialState);
  const [paymentMethod, setPaymentMethod] = useState("cod");
  const getCarddata = useSelector((state) => state.cardItems);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [total, setTotal] = useState(0);

  const [error, setError] = useState(null);

  //   console.log("User is", user);

  const handleCheckoutDataChange = (e) => {
    setCheckoutData({ ...checkoutData, [e.target.name]: e.target.value });
  };

  // useEffect(() => {
  //   if (user) {
  //     console.log(user.user);
  //     setCheckoutData({
  //       ...checkoutData,
  //       ...user.user,
  //       phone: user.user.phone_no,
  //     });
  //   }
  // }, [user]);

  useEffect(() => {
    if (getCarddata) {
      if (getCarddata.cardData) {
        //   Variable to store total
        let res = 0;
        // Variable to store ordered Data
        let temp = [];
        for (let i = 0; i < getCarddata.cardData.length; i++) {
          res =
            res +
            getCarddata.cardData[i].quantity *
              parseInt(getCarddata.cardData[i].price);

          temp.push({
            product_id: getCarddata.cardData[i].id,
            qty: getCarddata.cardData[i].quantity,
            price: getCarddata.cardData[i].price,
          });
        }

        setTotal(res);
        setCheckoutData({
          ...checkoutData,
          items: temp,
          ...user.user,
          phone: user.user.phone_no,
        });
      }
    }
  }, [getCarddata, user]);

  console.log("Checkout data is", checkoutData);

  const Order = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_URL}/order/placeorder`,
      data: {
        ...checkoutData,
        address:
          checkoutData.street +
          "," +
          checkoutData.city +
          "," +
          checkoutData.state,
        user_id: user?.user.id,
        payment_method: paymentMethod,
        total_amount: total,
      },
      headers: {
        Authorization: `Bearer ${user?.access_token}`,
      },
    })
      .then((res) => {
        if (res.data.status) {
          navigate("/");

          dispatch(clearCart());
          enqueueSnackbar("Your order has been placed successfully", {
            variant: "success",
          });
        } else {
          setError("Failed to place order.Try Again later");
          enqueueSnackbar("Failed to place order.Try Again later", {
            variant: "error",
          });
          window.scrollTo(0, 0);
        }
      })
      .catch((e) => {
        setError("Failed to place order.Try Again later");
        window.scrollTo(0, 0);
        enqueueSnackbar("Failed to place order.Try Again later", {
          variant: "error",
        });
      });

    setLoading(false);
    setTimeout(() => {
      setError(null);
    }, 5000);
  };
  return (
    <>
      <div className="container checkout-container">
        <div className="row">
          <div className="col-md-12 checkout-header">
            <h2>Billing</h2>
            <div>
              <h3>Checkout</h3>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-md-8 checkout-section">
              {error ? (
                <Alert severity="error" className="mb-4">
                  {error}
                </Alert>
              ) : null}

              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="">Full Name</label>
                  <input
                    type="text"
                    placeholder="Full Name"
                    className="form-control"
                    name="name"
                    value={checkoutData.name}
                    onChange={handleCheckoutDataChange}
                    required
                  />
                </div>
                <br />
              </div>
              <br />
              <div className="row ">
                <div className="col-md-6">
                  <label htmlFor=""> Phone Number</label>
                  <input
                    type="number"
                    placeholder="+91"
                    className="form-control"
                    name="phone"
                    value={checkoutData.phone}
                    onChange={handleCheckoutDataChange}
                    required
                  />
                  <br />
                </div>

                <div className="col-md-6">
                  <label htmlFor="">State</label>
                  <input
                    type="text"
                    placeholder="state"
                    className="form-control form-control-second"
                    required
                    name="state"
                    value={checkoutData.state}
                    onChange={handleCheckoutDataChange}
                  />
                </div>
              </div>

              <div className="row ">
                <div className="col-md-6">
                  <label htmlFor="">City</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="City"
                    required
                    name="city"
                    value={checkoutData.city}
                    onChange={handleCheckoutDataChange}
                  />
                </div>
                <div className="col-md-6">
                  <label htmlFor="">Street Name</label>
                  <input
                    type="text"
                    placeholder="Street Name "
                    className="form-control form-control-second"
                    required
                    name="street"
                    value={checkoutData.street}
                    onChange={handleCheckoutDataChange}
                  />
                </div>
              </div>
              <br />
              <div className="row ">
                <div className="col-md-6">
                  <label htmlFor="">Zip Code</label>
                  <input
                    type="text"
                    placeholder="Zip Code"
                    className="form-control"
                    required
                    name="postal_code"
                    value={checkoutData.postal_code}
                    onChange={handleCheckoutDataChange}
                  />
                </div>
                <div className="col-md-12">
                  <Payment
                    setPaymentMethod={setPaymentMethod}
                    paymentMethod={paymentMethod}
                  />
                </div>
              </div>

              <div className="modal-footer profile-changes-btn">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <button type="button" className="button" onClick={Order}>
                    Place Order
                  </button>
                )}
              </div>

              {/* <LoadingButton
                color="success"
                loading={loading}
                variant="contained"
                onClick={Order}
                className=" footer-submit checkout-submit mt-4"
              >
                Place Order
              </LoadingButton> */}
            </div>

            <div className="col-md-4">
              <Summary items={getCarddata?.cardData} total={total} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Checkout;
