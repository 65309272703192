import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import featurelist from "../../Components/BodyComponents/featuredProducts/Featurelist";
import axios from "axios";
import { useParams } from "react-router-dom";
import Skeletoncard from "../../Components/BodyComponents/skeletoncard/skeletoncard";
import { Pagination } from "@mui/material";
// import Pagination from "../../Components/Pagination";
import { useSnackbar } from "notistack";
import { addToCart } from "../../Services/Action/Action";
import { addToWishlist } from "../../Services/Action/WishlistAction";
import { useDispatch } from "react-redux";

const CategoriesContent = () => {
  const params = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [highLow, setHighLow] = useState("");
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [oldNew, setOldNew] = useState("");
  const [inputName, setInputname] = useState("");

  const handler = (e, get) => {
    dispatch(addToCart(get));
    enqueueSnackbar("Item added to cart", {
      variant: "success",
    });
  };
  const handlerWish = (e, get) => {
    e.preventDefault();
    dispatch(addToWishlist(get));
    enqueueSnackbar("Item added to wishlist", {
      variant: "success",
    });
    console.log(get.price);
  };
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({});
  const [page, setPage] = useState(1);
  const handlePage = (e, value) => {
    e.preventDefault();
    setPage(value);
  };
  console.log(page, "page");
  const [categoriescontent, setCategoriescontent] = useState([]);
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    setFilterData(categoriescontent);
  }, [categoriescontent]);

  const filterHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    await axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/product/filter`,
      params: {
        price_filter: highLow,
        sort_filter: oldNew,
        min_price: min,
        max_price: max,
        name: inputName,
        category_slug: params.slug,
      },
    })
      .then((res) => {
        console.log("clicked filter Data", res.data);
        setFilterData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    axios({
      method: "GET",
      url: `${process.env.REACT_APP_API_URL}/product/getByCategory/${params.slug}`,
      params: {
        page: page,
      },
    })
      .then((res) => {
        console.log(res.data.products.data);
        setPagination(res.data.products);
        let response = res.data.products.data;
        setCategoriescontent(response);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "error message");
        setLoading(false);
      });
  }, [params.slug, page]);

  console.log("slug", params.slug);

  console.log("Categories content is", categoriescontent);
  return (
    <div className="categories-content-main">
      <div className="container">
        {/* <div className="categories-content-bar px-5">
          <input
            type="text"
            className="form-control search-name my-3"
            placeholder="Filter by name"
            value={inputName}
            onChange={(e) => setInputname(e.target.value)}
          />
          <div className="left-bar">
            <ul>
              <li>
                <p>{categoriescontent.length} items</p>
              </li>
              <li>
                <label>Sort by :</label>
                <select
                  className="categories-content-select"
                  onChange={(e) => setOldNew(e.target.value)}
                >
                  <option value="">Choose</option>
                  <option value="oldest">Oldest</option>
                  <option value="newest">Newest</option>
                </select>
              </li>
              <li>
                <label>Min Price :</label>
                <input
                  type="number"
                  value={min}
                  className="form-control-price"
                  onChange={(e) => setMin(e.target.value)}
                />
              </li>
              <li>
                <label>Max Price :</label>
                <input
                  type="number"
                  value={max}
                  className="form-control-price"
                  onChange={(e) => setMax(e.target.value)}
                />
              </li>
              <li>
                <label>Price by :</label>
                <select
                  className="categories-content-select"
                  placeholder="choose"
                  onChange={(e) => setHighLow(e.target.value)}
                >
                  <option value="">Choose</option>
                  <option value="high_to_low">High To Low</option>
                  <option value="low_to_high">Low To High</option>
                </select>
              </li>
            </ul>
          </div>
          <div className="filter-btn">
            <button onClick={filterHandler}>Filter</button>
          </div>
        </div> */}
        <div className="categories-content-body">
          <div className="row">
            {filterData.length > 0 || loading ? (
              <>
                {loading ? (
                  <>
                    <Skeletoncard />
                  </>
                ) : (
                  filterData.map((get, id) => {
                    return (
                      <div className="col-md-4 col-lg-3 " key={id}>
                        <div className="cart-main">
                          <div className="feature-img">
                            <NavLink to={`/${params.slug}/${get.slug}`}>
                              <img
                                src={get.image}
                                alt="featureproducts"
                                className="img-fluid"
                              />
                            </NavLink>
                          </div>
                          <div className="feature-data">
                            <p className="cart-content-name">{get.name}</p>
                            <p className="cart-content-price">${get.price}</p>
                          </div>
                          {/* <div className="cart_icons">
                            <div className="row">
                              <div className="col-lg-12 col-md-12">
                                <div className="cart-hand d-grid">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-block"
                                    onClick={(e) => handler(e, get)}
                                  >
                                    {" "}
                                    <i class="fas fa-shopping-cart pe-1"></i>
                                    ADD TO CART
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    );
                  })
                )}
              </>
            ) : (
              <h5 className="no-items mb-5">No items to show</h5>
            )}
          </div>
        </div>
        {/* 
        <div className="pagination-main mt-3">
          <Pagination
            totalRecords={pagination.total}
            pageLimit={pagination?.per_page}
            pageNeighbours={10}
          />
        </div> */}

        {loading === false && filterData.length > 0 && pagination.total ? (
          <>
            <div className="pagination-main">
              <Pagination
                shape="rounded"
                count={pagination?.last_page}
                page={page}
                onChange={handlePage}
                color="primary"
              />
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default CategoriesContent;
