import React, { useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import axios from "axios";
import { Link } from "react-router-dom";
import BannerLoader from "./BannerLoader";

const MainBanner = () => {
  const [allCarousels, setAllCarousels] = useState([]);
  const [featuredCategories, setFeaturedCategories] = useState([]);

  const [loading, setLoading] = useState(false);

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: featuredCategories.length > 4 ? 4 : featuredCategories.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow:
            featuredCategories.length > 4 ? 4 : featuredCategories.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow:
            featuredCategories.length > 2 ? 2 : featuredCategories.length,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);

      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/carousel/get`,
      })
        .then((res) => setAllCarousels(res.data.data))
        .catch((e) => console.log(e.message));

      await axios({
        method: "get",
        url: `${process.env.REACT_APP_API_URL}/category/getFeature`,
      })
        .then((res) => setFeaturedCategories(res.data.data))
        .catch((e) => console.log(e.message));

      setLoading(false);
    };

    fetch();
  }, []);

  return (
    <div className="main-banner">
      {loading ? (
        <BannerLoader />
      ) : (
        <div className="">
          <div
            id="carouselExampleControls"
            class="carousel slide"
            data-bs-ride="carousel"
          >
            <div class="carousel-inner">
              <div class="carousel-item active main-banner-img">
                <img
                  src={allCarousels.length > 0 ? allCarousels[0].image : ""}
                  class="d-block w-100"
                  alt=".banner"
                />
              </div>
              {allCarousels.slice(1, allCarousels.length).map((item, id) => (
                <div className="carousel-item main-banner-img" key={id}>
                  <img src={item.image} class="d-block w-100" alt="banner" />
                </div>
              ))}
            </div>
            <button
              class="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button
              class="carousel-control-next"
              type="button"
              data-bs-target="#carouselExampleControls"
              data-bs-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
        </div>
      )}

      <div className="container">
        {/* <div className="main-topic mt-5">
          <p>
            Our <span>Collections</span>
          </p>
        </div> */}
        <div className="category-main">
          <div className="row">
            <Slider {...settings} className="slider-main">
              {featuredCategories.map((item, id) => (
                <div className="col-md-2" key={id}>
                  <div className="category-img">
                    <Link to={`/category/${item.slug}`}>
                      <img src={item.image} alt="Image" />
                      <p>{item.name}</p>
                    </Link>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;
