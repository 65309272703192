import React, { useEffect } from "react";
import MainBanner from "../Components/BodyComponents/mainBanner/MainBanner";
import FeaturedProducts from "../Components/BodyComponents/featuredProducts/FeaturedProducts";
import Secondbanner from "../Components/BodyComponents/secondbanner/Secondbanner";
import Whychooseus from "../Components/BodyComponents/Whychooseus/Whychooseus";
import Secondfeatured from "../Components/BodyComponents/secondfeatured/Secondfeatured";
import Learnabout from "../Components/BodyComponents/Learnabout/Learnabout";
import Snackbar from "../Components/BodyComponents/snackbar/snackbar";
import { useLocation } from "react-router-dom";

const Home = () => {
  const [open, setOpen] = React.useState(false);
  const { state } = useLocation();

  useEffect(() => {
    if (state) {
      setOpen(state.openSnackbar);
    }

    setTimeout(() => {
      window.history.replaceState({}, document.title);
    }, 1000);
  }, [state]);

  return (
    <>
      <MainBanner />
      <FeaturedProducts />
      <Secondbanner />
      <Whychooseus />
      <Secondfeatured />
      {/* <Learnabout /> */}
      <Snackbar
        open={open}
        setOpen={setOpen}
        message={state?.message ? state?.message : "Login successful"}
      />
    </>
  );
};
export default Home;
